import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CustomButton from '../CustomButton';
import { Hidden, Grid, Typography, withStyles } from '@material-ui/core';
import Container from '../Container';

import pxToRem from '../../lib/pxToRem';

import backgroundImage from '../../images/hero/hero-home.svg';
import backgroundImageSmall from '../../images/hero/hero-home-small.svg';

const styles = (theme) => ({
	heroSection: {
		background: `url(${backgroundImage}) no-repeat right bottom`,
		paddingTop: pxToRem(70),
		paddingBottom: pxToRem(170),
		[theme.breakpoints.down('md')]: {
			paddingTop: pxToRem(40),
			paddingBottom: pxToRem(80),
			backgroundSize: '50%'
		},
		[theme.breakpoints.down('sm')]: {
			backgroundImage: 'none',
			paddingTop: 0,
			paddingBottom: 0
		}
	},
	container: {
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 0
		}
	},
	heroContent: {
		maxWidth: 620,
		[theme.breakpoints.down('md')]: {
			maxWidth: 570
		},
		[theme.breakpoints.down('sm')]: {
			margin: '0 auto',
			maxWidth: 400,
			textAlign: 'center'
		},
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%'
		},
		'& h2': {
			marginBottom: pxToRem(30),
			fontWeight: '400'
		},
		'& h3': {
			fontWeight: 'normal',
			marginBottom: pxToRem(66)
		}
	},
	gridContainer: {
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center'
		}
	},
	linkButton: {
		'&:hover': {
			textDecoration: 'none'
		}
	},
	img: {
		width: '100%'
	}
});

function HeroSection(props) {
	const { classes } = props;
	const { i18n } = useTranslation();

	return (
		<section className={classes.heroSection}>
			<Container className={classes.container}>
				<div className={classes.heroContent}>
					<Typography component="h1" variant="h1">
						{i18n.t('Testing at heart')}
						<br />
						{i18n.t('Coding in mind')}
					</Typography>

					<Hidden smDown>
						<Typography variant="h2" gutterBottom>
							{i18n.t('heroSubtitle')}
						</Typography>
					</Hidden>

					<Typography variant="h3" color="textSecondary">
						{i18n.t('heroText')}
					</Typography>

					<Grid container spacing={16} className={classes.gridContainer}>
						<Grid item>
							<Link to ="/contact" className={classes.linkButton}>
								<CustomButton color="primary">
									{i18n.t('Contact Us')}
								</CustomButton>
							</Link>
						</Grid>
						<Hidden smDown>
							<Grid item>
							<Link to ="/services" className={classes.linkButton}>
								<CustomButton color="secondary">
									{i18n.t('Our Services')}
								</CustomButton>
							</Link>
							</Grid>
						</Hidden>
					</Grid>
				</div>
			</Container>

			<Hidden mdUp>
				<img src={backgroundImageSmall} alt="Ilustration" className={classes.img} />
			</Hidden>
		</section>
	);
}

HeroSection.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HeroSection);
