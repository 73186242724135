import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Container from '../components/Container';
import { withStyles } from '@material-ui/core';

import bg from '../images/background/404-bg.jpg';
import pxToRem from '../lib/pxToRem';
import CustomButton from '../components/CustomButton';

const styles = (theme) => ({
  section: {
    background: `url(${bg}) no-repeat center/cover`,
  },
  notFound: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: pxToRem(20),
    width: '100%',
    '& h2': {
      marginBottom: pxToRem(120),
      fontSize: pxToRem(260),
      fontWeight: 600,
      lineHeight: pxToRem(63),
      color: '#460F76',
    },
    '& p': {
      fontSize: pxToRem(30),
      color: '#460F76',
    }
  },
  linkButton: {
    marginTop: pxToRem(80),
    '&:hover': {
      textDecoration: 'none',
    }
  }
});

function NotFound(props) {
	const { classes } = props;
	const { i18n } = useTranslation();

	return (
		<main>
			<section className={classes.section}>
				<Container>
          <div className={classes.notFound}>
            <div>
              <h2>404</h2>
            </div>
            <div>
              <p>
                {i18n.t('page not found')}
              </p>
            </div>

            <Link to="/" className={classes.linkButton}>
              <CustomButton color="primary">
                Back to homepage
              </CustomButton>
            </Link>
          </div>
				</Container>
			</section>
		</main>
	);
}

NotFound.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NotFound);
