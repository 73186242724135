import embededTestingIcon from '../images/icons/embeded-testing.svg';
import guiTestingIcon from '../images/icons/gui-testing.svg';
import mobileTestingIcon from '../images/icons/mobile-testing.svg';
import performanceTestingIcon from '../images/icons/performance-testing.svg';
import toolDevIcon from '../images/icons/tool-dev.svg';
import webTestingIcon from '../images/icons/web-testing.svg';

export const howWeWork = [
	{
		title: 'Engineer Leasing',
		items: [
			'You decide about the number of engineers needed',
			'We build the team',
			'You manage the team',
			'Flat rate per day of work'
		]
	},
	{
		title: 'Projects',
		items: [
			'You provide us requirements and time frame',
			'We estimate effort',
			'We build the team',
			'We manage the team to deliver the project',
			'You overview the progress during entire project duration',
			'Agreed in advance final cost of a project'
		]
	}
];

export const extraSquishServies = [
	{
		id: 'squish-kick-off',
		title: 'Squish Kick off',
		desc: 'Squish Kick-Off is a five-day service engagement dedicated to new Squish users. It offers Squish training, support in making Squish tests part of CI/CD, and test development by Cyber Alpaca experts.',
		items: [
			{headline : 'Squish Training using your Application Under Test', desc: 'The first step in the project is 1.5 days of formal training on using Squish. Cyber Alpaca conducts the training using your Application Under Test, not a dummy example application.'},
			{headline : 'Introduction to SQUAPE library', desc: 'A short introduction to SQUAPE (Squish API Python Extension) to boost the efficiency in automated test case development.'},
			{headline : 'Development of the first set of tests', desc: 'We will work with your team to develop an initial set of automated tests that you can use immediately to verify your application\'s GUI. Together with the tests, we implement an initial version of the test framework—the foundation for future tests. Our work is transparent - we use configuration control tools to ensure version control and traceability.'},
			{headline : 'Nightly Runs', desc: 'Start by running your regression tests overnight to detect any problems early on. During this process, Cyber Alpaca will assist you in setting up a CI/CD solution in your environment to run Squish tests and sharing the results with your team through the Test Center, which will improve transparency and collaboration within your team.'}
		]
	},
	{
		id: 'squish-boost',
		title: 'Squish Boost',
		desc: 'Squish Boost is a five-day service engagement dedicated to existing Squish users that reviews existing Squish tests, implements improvements, and adds more tests implemented by Cyber Alpaca experts. ',
		items: [
			{headline:'The review of existing automated tests', desc: 'The engagement starts with reviewing existing automated tests. We focus on the overall design, which allows us to minimize maintenance efforts and speed up test creation. '},
			{headline:'Squish Refresh Training using your Application Under Test',  desc: 'The next step in the project is one day of Squish training. Cyber Alpaca conducts the training using your Application Under Test, not a dummy example application.'},
			{headline:'Introduction to SQUAPE library',  desc: 'A short introduction to SQUAPE (Squish API Python Extension) to boost the efficiency in automated test case development.'},
			{headline:'Improving the current tests and creating new ones', desc: 'We will collaborate with your team to improve your current framework by making your tests more resilient, simpler to maintain, and quicker to create additional tests. Our approach is open and transparent. We utilize configuration control tools to guarantee version control and traceability.'}
		]
	}
];

export const services = [
	{
		id: 'automated-gui-testing',
		title: 'Automated GUI testing',
		text: 'automatedGuiTestingText',
		howWeCanHelp : 'HowCanWeHelp',
		textMore: 'automatedGuiTestingTextMore',
		icon: guiTestingIcon
	},
	{
		id: 'embedded-testing',
		title: 'Embedded testing',
		text: 'embeddedTestingText',
		icon: embededTestingIcon,
		howWeCanHelp : 'HowCanWeHelp',
		textMore: 'embeddedTestingTextMore'
	},
	{
		id: 'mobile-app-testing',
		title: 'Mobile application testing',
		text: 'mobileApplicationTestingText',
		icon: mobileTestingIcon,
		howWeCanHelp : 'HowCanWeHelp',
		textMore: 'mobileApplicationTestingTextMore'
	},
	{
		id: 'web-testing',
		title: 'Web testing',
		text: 'webTestingText',
		icon: webTestingIcon,
		howWeCanHelp : 'HowCanWeHelp',
		textMore: 'webTestingTextMore',		
	},
	{
		id: 'consulting-training',
		title: 'trainingConsulting',
		text: 'trainingConsultingText',
		icon: performanceTestingIcon		
	},

	{
		id: 'tool-integration-dev',
		title: 'Tool integration development',
		text: 'toolIntegrationDevelopmentText',
		icon: toolDevIcon,
		howWeCanHelp : 'HowCanWeHelp',
		textMore: 'toolIntegrationDevelopmentTextMore',
	}
];
