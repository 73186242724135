export const contact = [
	{
		name: 'Phone number',
		value: '+48 506 297 522',
		src: 'tel:+48 506 297 522'
	},
	{
		name: 'email',
		value: 'contact@cyberalpaca.com',
		src: 'mailto:contact@cyberalpaca.com'
	},
	{
		name: 'address',
		value: 'Ogarna 44/45/9, 80-826 Gdańsk, Poland',
		src: 'https://goo.gl/maps/eMttwch4rc7JJFh69'
	}
];
