import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
	img: {
		maxWidth: '100%'
	}
});

function Image(props) {
	const { src, alt, classes, className, title } = props;

	return <img src={src} alt={alt} title={title} className={classNames(classes.img, className)} />;
}

Image.propTypes = {
	classes: PropTypes.object.isRequired,
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	className: PropTypes.string,
	title: PropTypes.string
};

Image.defaultProps = {
	className: null,
	title: null
};

export default withStyles(styles)(Image);
