import React from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import Image from '../Image';

const styles = (theme) => ({
	fluidSection: {
		backgroundColor: theme.palette.background.paper
	},
	fluidContent: {
		maxWidth: 1100,
		margin: '0 auto',
		textAlign: 'center',
		'& img': {
			paddingBottom: '2.5em'
		},
		'& h2': {
			marginBottom: '2.5rem'
		},
		'& h3': {
			marginBottom: '2.5rem',
			'& span': {
				color: theme.palette.primary.light
			}
		},
		'& p': {
			paddingBottom: '2.5em',
			lineHeight: '2rem'
		}
	}
});

function FluidContent(props) {
	const { classes, title, image, alt, children } = props;

	return (
		<div className={classes.fluidContent}>
			{image && <Image src={image} alt={alt} />}

			<Typography variant="h2" color="textPrimary" className={classes.fluidContentTitle} gutterBottom>
				{title}
			</Typography>

			{children}
		</div>
	);
}

FluidContent.propTypes = {
	classes: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	logo: PropTypes.string,
	alt: PropTypes.string,
	children: PropTypes.node
};

FluidContent.defaultProps = {
	children: null,
	logo: null,
	alt: null
};

export default withStyles(styles)(FluidContent);
