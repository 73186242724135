import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { first } from 'lodash';
import Container from '../Container';
import Image from '../Image';
import { Grid, Paper, Typography, withStyles } from '@material-ui/core';

import patternDots from '../../images/background/pattern-dots.svg';
import patternDot from '../../images/background/pattern-dot.svg';
import arrowPurple from '../../images/icons/arrow-purple.svg';
import arrowBlue from '../../images/icons/arrow-blue.svg';
import patternBlue from '../../images/background/pattern-blue.svg';
import patternPurple from '../../images/background/pattern-purple.svg';

import pxToRem from '../../lib/pxToRem';

import { extraSquishServies } from '../../data/services';

const styles = (theme) => ({
	paper: {
		minHeight: pxToRem(400),
		marginTop: pxToRem(50),
		padding: `${pxToRem(70)} ${pxToRem(70)} ${pxToRem(120)} ${pxToRem(70)}`,
		boxShadow: '0px 40px 60px rgba(151, 169, 204, 0.4)',
		borderRadius: 10,
		[theme.breakpoints.down('sm')]: {
			padding: `${pxToRem(20)} ${pxToRem(20)} ${pxToRem(90)} ${pxToRem(20)}`
		},
		'& h3': {
			fontSize: pxToRem(30),
			lineHeight: pxToRem(60),
			marginBottom: pxToRem(40),
			[theme.breakpoints.down('sm')]: {
				fontSize: pxToRem(22),
				lineHight: pxToRem(20)
			}
		}
	},
	h2: {
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(28)
		}
	},
	paragraph: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	purple: {
		background: `#fff url(${patternPurple}) no-repeat bottom`
	},
	blue: {
		background: `#fff url(${patternBlue}) no-repeat bottom`
	},
	paperBody: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: pxToRem(20),
		marginTop: pxToRem(30),
		'& img': {
			marginRight: pxToRem(18)
		},
		'& p': {
			fontWeight: 600,
			lineHeight: pxToRem(22)
		},
		[theme.breakpoints.down('sm')]: {}
	},
	pattern: {
		textAlign: 'center',
		marginTop: pxToRem(30),
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	}
});

function SquishExtraService(props) {
	const { classes } = props;
	const { i18n } = useTranslation();

	return (
		<div className={classes.work}>
			<Container>
				<Grid container justify="center" alignItems="center" direction="column">
					<Typography variant="h2" component="h1" className={classes.h2}>
						{i18n.t('squishExtraService')}
					</Typography>
					<Typography paragraph color="textSecondary" className={classes.paragraph}>
						{i18n.t('squishExtraServiceText')}
					</Typography>
				</Grid>
				<Grid container spacing={24} justify="center">
					{extraSquishServies.map((element) => {
						const isFirst = first(extraSquishServies).title === element.title;

						return (
							<Grid id={element.id} key={element.id} item xs={12} sm={6}>
								<Paper className={classnames(classes.paper, isFirst ? classes.purple : classes.blue)}>
									<Typography variant="h3">{i18n.t(element.title)}</Typography>
									<Typography paragraph color="textSecondary">{i18n.t(element.desc)}</Typography>
									{element.items.map((item) => (
										<div key={item.headline+item.desc}>
										<div key={item.headline} className={classes.paperBody}>
											<Image src={isFirst ? arrowPurple : arrowBlue} alt="Arrow icon" />
											<Typography color="textSecondary">{i18n.t(item.headline)}</Typography>
										</div>
										<div key={item.desc}>
											<Typography paragraph color="textSecondary">{i18n.t(item.desc)}</Typography>
										</div>
										</div>
									))}
								</Paper>
								<div className={classes.pattern}>
									<Image src={isFirst ? patternDot : patternDots} alt="Dots decoration" />
								</div>
							</Grid>
						);
					})}
				</Grid>
			</Container>
		</div>
	);
}

SquishExtraService.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SquishExtraService);
