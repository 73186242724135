import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { Card, CardContent, Grid, Typography, withStyles } from '@material-ui/core';

import backgroundImage from '../images/background/pattern-main.svg';
import logo from '../images/cyberalpaca-logo-horizontal.svg';
import Container from '../components/Container';
import FluidContent from '../components/FluidContent';
import HeroSection from '../components/HeroSection';
import Image from '../components/Image';

import pxToRem from '../lib/pxToRem';
import { cards } from '../data/cardsHome';

const styles = (theme) => ({
	h1: {
		margin: `${pxToRem(30)} 0 ${pxToRem(93)} 0`,
		[theme.breakpoints.down('sm')]: {
			margin: `${pxToRem(10)} 0 ${pxToRem(20)} 0`
		}
	},
	grid: {
		[theme.breakpoints.down('sm')]: {
			padding: pxToRem(10)
		}
	},
	gridCard: {
		width: '100%',
		minHeight: pxToRem(320),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginBottom: pxToRem(30),
		padding: pxToRem(20),
		[theme.breakpoints.down('sm')]: {
			padding: pxToRem(10)
		},
		[theme.breakpoints.down('sm')]: {
			marginBottom: pxToRem(2),
			padding: pxToRem(5),
			minHeight: pxToRem(280)
		}
	},
	card: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: pxToRem(20),
		borderRadius: 50,
		boxShadow: '0px 40px 60px rgba(151, 169, 204, 0.4)',
		textAlign: 'center',
		'& p': {
			fontSize: pxToRem(18),
			fontWeight: 'bold',
			lineHeight: pxToRem(22)
		},
		'&:hover': {
			boxShadow: '0px 40px 60px rgba(151, 169, 204, 0.7)'
		}
	},
	cardContent: {
		flexGrow: 1,
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column'
	},
	cardImg: {
		marginBottom: pxToRem(15)
	},
	cardAction: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		'&:hover': {
			textDecoration: 'none'
		}
	},
	action: {
		justifyContent: 'center'
	},
	link: {
		fontSize: pxToRem(13),
		fontWeight: 'bold',
		lineHeight: pxToRem(16),
		color: theme.palette.primary.light,
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	sectionWhite: {
		backgroundColor: theme.palette.background.paper
	},
	sectionSecondary: {
		background: `url(${backgroundImage}) no-repeat left bottom/15%, linear-gradient(to left bottom, #FBFBFB 12.79%, #F9F9FB 33.77%, #F0F3F9 111.23%)`,
		[theme.breakpoints.down('md')]: {
			backgroundSize: '40%'
		},
		[theme.breakpoints.down('sm')]: {
			backgroundImage: 'none'
		}
	},
	fluidContentLink: {
		fontSize: pxToRem(22),
		lineHeight: pxToRem(22),
		fontWeight: 'bold',
		color: theme.palette.primary.light
	}
});

function Home(props) {
	const { classes } = props;
	const { i18n } = useTranslation();

	return (
		<main>
			<HeroSection />

			<section className={classes.sectionSecondary}>
				<Container>
					<Grid container justify="center" className={classes.grid}>
						<Grid container justify="center">
							<Typography variant="h2" color="textPrimary" className={classes.h1}>
								{i18n.t('What we do')}
							</Typography>
						</Grid>
						{cards.map((card) => (
							<Grid item key={card.name} sm={6} md={4} lg={3} className={classes.gridCard}>
								<Card className={classes.card}>
									<HashLink
										scroll={(el) => {
											el.scrollIntoView(true);
											window.scrollBy(0, -85);
										}}
										smooth
										to={card.path}
										className={classes.cardAction}
									>
										<CardContent className={classes.cardContent}>
											<Image src={card.icon} alt={card.name} className={classes.cardImg} />
											<Typography gutterBottom>{i18n.t(card.name)}</Typography>
										</CardContent>
										<div className={classes.action}>
											<div to={card.path} className={classes.link}>
												{i18n.t('Read More')}
											</div>
										</div>
									</HashLink>
								</Card>
							</Grid>
						))}
					</Grid>
				</Container>
			</section>

			<section className={classes.sectionWhite}>
				<Container>
					<Container>
						<FluidContent title={i18n.t('Join Our Team')} image={logo} alt="Cyberalpaca logo">
							<Typography variant="h3" color="textPrimary" gutterBottom>
								{i18n.t('joinOurTeamSubtitle')} <span>{i18n.t('ambitious')}</span> {i18n.t('and')}{' '}
								<span> {i18n.t('interesting')}</span> {i18n.t('projects')}.
							</Typography>

							<Typography color="textSecondary" paragraph gutterBottom>
								{i18n.t('joinOurTeamText')}
								<br />
								{i18n.t('joinOurTeamLine')}
								<br />
								{i18n.t('Please send us your CV to')}
							</Typography>

							<Typography color="secondary" paragraph>
								<a href="mailto:jobs@cyberalpaca.com" className={classes.fluidContentLink}>
									jobs@cyberalpaca.com
								</a>
							</Typography>
						</FluidContent>
					</Container>
				</Container>
			</section>
		</main>
	);
}

Home.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);
