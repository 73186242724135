import React from 'react';
import PropTypes from 'prop-types';
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

import Image from '../Image';
import pxToRem from '../../lib/pxToRem';

const breakpoints = createBreakpoints({});
const styledBy = (property, mapping) => props => mapping[props[property]];

const useStyles = makeStyles({
  root: {
    background: styledBy('color', {
      primary: '#10ABF9',
      secondary: '#fff',
    }),
    color: styledBy('color', {
      primary: '#fff',
      secondary: '#460F76',
    }),
    border: styledBy('color', {
      primary: 0,
      secondary: '2px solid #460F76'
    }),
    borderRadius: 32,
    boxShadow: styledBy('color', {
      primary: '0px 4px 20px rgba(151, 169, 204, 0.813264);',
      secondary: 'none',
    }),
    height: pxToRem(50),
    textTransform: 'none',
    fontFamily: 'Montserrat Alternates',
    fontWeight: styledBy('color', {
      primary: 'bold',
      secondary: 'normal',
    }),
    fontSize: pxToRem(18),
    lineHeight: pxToRem(20),
    [breakpoints.down('md')]: {
      fontSize: pxToRem(16),
    },
    minWidth: 260,
    '&:hover' : {
      background: styledBy('color', {
        primary: '#0D89C7',
        secondary: '#7334AB',
      }),
      color: styledBy('color', {
        primary: '#fff',
        secondary: '#fff',
      }),
    }
  },
  pattern: {
    marginTop: pxToRem(-10),
    [breakpoints.down('sm')]: {
      display: 'none',
    }
  }
});

function CustomButton(props) {
  const { color, pattern, ...other } = props;
  const classes = useStyles(props);
  return (
    <>
      <Button className={classes.root} {...other} />
      { pattern &&
      <div className={classes.pattern}>
        <Image src={pattern} alt={pattern} />
      </div>
      }
    </>
  );
}

CustomButton.propTypes = {
  color: PropTypes.string.isRequired,
  pattern: PropTypes.string,
};

CustomButton.defaultProps = {
  pattern: null
};

export default CustomButton;