import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { first } from 'lodash';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Container from '../Container';

import { columnsFooter } from '../../data/footerLinks';
import pxToRem from '../../lib/pxToRem';

const styles = (theme) => ({
	footer: {
		background: 'linear-gradient(to right top, #200F3A, #4F2077)',
		[theme.breakpoints.down('sm')]: {
			paddingTop: pxToRem(20)
		}
	},
	gridFirst: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	},
	typographyTitle: {
		paddingBottom: '2em',
		fontWeight: 'bold',
		'& a': {
			color: theme.palette.primary.main,
			textDecoration: 'none'
		}
	},
	typographyLink: {
		paddingBottom: '1.5em'
	},
	link: {
		fontWeight: 400,
		color: theme.palette.primary.contrastText,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	mailto: {
		fontWeight: 600
	},
	linkLogo: {
		color: '#9543DD',
		textDecoration: 'none',
		cursor: 'pointer',
		'&:focus, &:hover': {
			textDecoration: 'none'
		}
	},
	typographyLogo: {
		height: '90%',
		display: 'flex',
		alignItems: 'flex-end',
		fontWeight: 'bold',
		fontFamily: 'Montserrat Alternates',
		fontSize: pxToRem(35)
	}
});

function Footer(props) {
	const { classes } = props;
	const { i18n } = useTranslation();

	return (
		<footer className={classes.footer}>
			<Container>
				{columnsFooter.map((column) => {
					const isFirst = first(columnsFooter).name === column.name;

					return (
						<Grid item key={column.name} xs={12} sm={6} md={3} lg={3} className={isFirst ? classes.gridFirst : null}>
							<Typography gutterBottom color="primary" variant="h3" className={classes.typographyTitle}>
								<Link to={column.path}>{i18n.t(column.name)}</Link>
							</Typography>
							{column.links &&
								column.links.map((link) => (
									<Typography key={link.name} className={classes.typographyLink} variant="body1">
										{link.path ? (
											<HashLink
												scroll={(el) => {
													el.scrollIntoView(true);
													window.scrollBy(0, -85);
												}}
												smooth
												to={link.path}
												className={classes.link}
											>
												{i18n.t(link.name)}
											</HashLink>
										) : (
											<a
												className={classNames(classes.link, !link.href && classes.mailto)}
												target="_blank"
												rel="noopener noreferrer"
												href={link.href ? link.href : link.mailto ? `mailto:${link.mailto}` : `tel:${link.phone}`}
											>
												{i18n.t(link.name)}
											</a>
										)}
									</Typography>
								))}
						</Grid>
					);
				})}
				<Grid item sm={6} md={3} lg={3}>
					<h2 className={classes.typographyLogo}>
						<Link to="/" className={classes.linkLogo}>
							CyberAlpaca
						</Link>
					</h2>
				</Grid>
			</Container>
		</footer>
	);
}

Footer.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
