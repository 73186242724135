import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  withStyles,
  Paper,
  Typography,
  Grid,
  Hidden
} from '@material-ui/core';
import Container from '../Container';
import Image from '../Image';

import { services } from '../../data/services';
import pxToRem from '../../lib/pxToRem';

import arrow from '../../images/icons/arrow-small.svg';
import arrowDefault from '../../images/icons/arrow-small-default.svg';

const styles = theme => ({
  h1: {
    marginBottom: pxToRem(40),
  },
  main: {
    paddingRight: pxToRem(15),
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    }
  },
  paper: {
    minHeight: pxToRem(60),
    padding: `${pxToRem(10)} ${pxToRem(20)}`,
    display: 'flex',
    alignItems: 'center',
    marginBottom: pxToRem(10),
    marginRight: pxToRem(30),
    borderRadius: pxToRem(10),
    boxShadow: `0px 15px 40px rgba(151, 169, 204, 0.4);`,
    cursor: 'pointer',
    backgroundColor: '#F9FBFF',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      '& h4': {
        marginBottom: 0,
        whiteSpace: 'normal',
      },
    },
    '&:hover': {
      backgroundColor: '#fff',
      '& div': {
        backgroundColor: '#fff',
      },
      '& h4': {
        color: theme.palette.primary.light,
      },
    },
    '&:last-of-type': {
      marginBottom: 0,
    }
  },
  typography: {
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    }
  },
  arrow: {
    margin: pxToRem(10),
    width: pxToRem(10),
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
    }
  },
  transformArrow: {
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
    }
  },
  active: {
    backgroundColor: '#fff',
    '& div': {
      backgroundColor: '#fff',
    },
    marginRight: 0,
    '& h4': {
      color: theme.palette.primary.light,
    }
  },
  gridContainer: {
    backgroundColor: '#F9FBFF',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      '& h4': {
        lineHeight: pxToRem(20),
      }
    },
    '& img': {
      margin: pxToRem(10),
      width: pxToRem(50),
      height: pxToRem(50),
    },
  },
  aside: {
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: pxToRem(20),
      height: 'auto',
    },
    '& h4': {
      marginBottom: pxToRem(30),
    },
  },
  paperText: {
    height: '100%',
    borderRadius: pxToRem(10),
    boxShadow: `0px 40px 60px rgba(151, 169, 204, 0.4)`,
  },
  paperGrid: {
    padding: `${pxToRem(35)} ${pxToRem(45)}`,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      marginBottom: pxToRem(10),
    },
  },
  divider: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: `${pxToRem(30)} 0`
    }
  },
  checked: {
    [theme.breakpoints.down('sm')]: {
      '& h4': {
        color: 'inherit',
      }
    },
  },
  hidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  logoServices: {
    textAlign: 'center'
  }
});

class ServicesList extends React.Component {
  state = {
    id: services[0].id,
    service: services[0],
    checked: true,
  };

  componentDidMount() {
    if (window.location.hash) {
      const service = this.findService();

      this.setState({
        id: window.location.hash.slice(1),
        service: service,
        checked: false
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (window.location.hash && window.location.hash.slice(1) !== prevState.id) {
      const service = this.findService();

      this.setState( state => ({
        id: window.location.hash.slice(1),
        service: service,
        checked: false
      }))
    }
  }

  findService = () => (
    services.find(element => {
      const hash = window.location.hash.slice(1);

      if (element.id === hash) {
        return element
      }
      return null;
  }))

  handleChange = (service) => {
    this.setState( state => ({
      service: service,
      checked: !state.checked
    }));
  };

  render() {
    const { classes } = this.props;
    const { service, checked } = this.state;

    const BlockServices = () => {
      const { i18n } = useTranslation();

      return (
        <Grid key={services.id} item xs={12} sm={12} md={6} className={classNames(classes.aside, checked ? classes.hidden : null )}>
          <Paper id={service.id} className={classes.paperText}>
            <Grid container className={classes.paperGrid} justify="space-between">
              <Grid item>
                <Typography variant="h3">
                  {i18n.t(service.title)}
                </Typography>
                <Typography color="textSecondary">
                  {i18n.t(service.text)}
                </Typography>
                <Typography variant="h4"/>
                <Typography variant="h4">
                  {i18n.t(service.howWeCanHelp)}
                </Typography>
                <Typography color="textSecondary">
                  {i18n.t(service.textMore)}
                </Typography>

              </Grid>


            </Grid>
          </Paper>
        </Grid>
      )
    };
    const MainBlockServices = () => {
      const { i18n } = useTranslation();

      return (
        <>
          <Grid container justify="center">
            <Typography variant="h1" className={classes.h1}>
              {i18n.t('Our Services')}
            </Typography>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} sm={12} md={6} className={classes.main}>
              {services.map(element => (
                <React.Fragment key={`${element.title}_1`}>
                  <Paper
                    key={element.title}
                    className={classNames(classes.paper, service.id === element.id ? classes.active : null, checked ? classes.checked : null)}
                    onClick={this.handleChange.bind(this, element)}
                  >
                    <Grid container alignItems="center" alignContent="center" className={classes.gridContainer}>
                      <Image src={element.icon} alt={i18n.t(element.title)}/>
                      <Typography variant="h4" className={classes.typography}>
                        {i18n.t(element.title)}
                      </Typography>
                    </Grid>
                    <Image
                      src={service.id === element.id ? arrow : arrowDefault}
                      alt={i18n.t("Arrow small")}
                      className={classNames(classes.arrow, checked ? classes.transformArrow : null)}/>
                  </Paper>
                  {service.id === element.id &&
                    <Hidden mdUp key={element.id}>
                      <BlockServices />
                    </Hidden>
                  }
                 </React.Fragment>
              ))}
            </Grid>

            <Hidden smDown>
              <BlockServices />
            </Hidden>
          </Grid>
        </>
      )
    }
    return (
      <div className={classes.root}>
        <Container>
          <MainBlockServices />
        </Container>
      </div>
    );
  }
}

ServicesList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ServicesList);
