import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import pxToRem from '../../lib/pxToRem';

const styles = (theme) => ({
	layout: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 2.5,
		marginRight: theme.spacing.unit * 2.5,
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing.unit * 10,
			marginRight: theme.spacing.unit * 10
		},
		[theme.breakpoints.up('lg')]: {
			width: 1200,
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	columnGrid: {
		padding: `${theme.spacing.unit * 8}px 0`,
		[theme.breakpoints.down('sm')]: {
			paddingTop: pxToRem(20)
		}
	}
});

function Container(props) {
	const { classes, children, className } = props;

	return (
		<Grid container className={classNames(classes.layout, classes.columnGrid, className)}>
			{children}
		</Grid>
	);
}

Container.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string
};

Container.defaultProps = {
	className: null
};

export default withStyles(styles)(Container);
