import pxToRem from '../lib/pxToRem';
import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});

export const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#10ABF9',
			light: '#9543DD',
			dark: '#460F76',
			contrastText: '#fff'
		},
		secondary: {
			main: '#5D6D8C',
			light: '#F9F9FB',
			dark: '#100F3A',
			contrastText: '#fff'
		},
		text: {
			primary: '#200F3A'
		}
	},
	typography: {
		useNextVariants: true,
		fontFamily: [ 'Montserrat', 'Montserrat Alternates', 'Arial', 'sans-serif' ].join(','),
		fontSize: pxToRem(18),
		lineHeight: pxToRem(36),
		h1: {
			marginBottom: pxToRem(40),
			lineHeight: pxToRem(60),
			fontSize: pxToRem(40),
			fontWeight: 'bold',
			color: '#460F76',
			[breakpoints.down('md')]: {
				lineHeight: pxToRem(37),
				fontSize: pxToRem(30),
				marginBottom: pxToRem(30)
			}
		},
		h2: {
			marginBottom: pxToRem(40),
			fontSize: pxToRem(32),
			fontWeight: 'bold',
			lineHeight: pxToRem(37),
			[breakpoints.down('md')]: {
				lineHeight: pxToRem(30),
				fontSize: pxToRem(22),
				marginBottom: pxToRem(20)
			}
		},
		h3: {
			fontSize: pxToRem(18),
			fontWeight: 'bold',
			lineHeight: pxToRem(30),
			marginBottom: pxToRem(30),
			[breakpoints.down('md')]: {
				lineHeight: pxToRem(30),
				fontSize: pxToRem(15),
				marginBottom: pxToRem(20)
			}
		},
		h4: {
			fontSize: pxToRem(16),
			fontWeight: 'bold',
			lineHeight: pxToRem(24),
			[breakpoints.down('md')]: {
				lineHeight: pxToRem(30),
				fontSize: pxToRem(15),
				marginBottom: pxToRem(20)
			}
		},
		body1: {
			fontSize: pxToRem(16),
			lineHeight: pxToRem(36)
		}
	}
});
