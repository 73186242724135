import introductionImage from '../images/about/introduction.svg';
import historyImage from '../images/about/history.svg';
import missionImage from '../images/about/mission.svg';

export const aboutRows = [
	{
		title: 'introduction',
		text: 'introductionText',
		img: introductionImage
	},
	{
		title: 'mission',
		text: 'misstionText',
		img: missionImage
	},
	{
		title: 'history',
		text: 'historyText',
		img: historyImage
	}
];
