import tomaszImage from '../images/team/tomasz_profile_pic.png';
import jakubImage from '../images/team/jakub_profile_pic.png';
import edImage from '../images/team/ed_profile_pic.png';

export const teamRows = [
	{
		title: 'tomasz',
		text: 'tomaszBio',
		img: tomaszImage
	},
	{
		title: 'jakub',
		text: 'jakubBio',
		img: jakubImage
	},
	{
		title: 'eduard',
		text: 'eduardBio',
		img: edImage
	}
];
