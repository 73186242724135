import medicalImage from '../images/industries/medical.svg';
import aviationImage from '../images/industries/aviation.svg';
import automotiveImage from '../images/industries/automotive.svg';

export const industriesRows = [
	{
		title: 'medical',
		text: 'medicalText',
		img: medicalImage
	},
	{
		title: 'aviation',
		text: 'aviationText',
		img: aviationImage
	},
	{
		title: 'automotive',
		text: 'automotiveText',
		img: automotiveImage
	}
];
