import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';

import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles';
import { Drawer, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Close } from '@material-ui/icons';

import Image from '../Image';

import pxToRem from '../../lib/pxToRem';
import { navLinks, languages } from '../../data/nav';

import logo from '../../images/cyberalpaca-logo.svg';
import pattern from '../../images/background/pattern-nav.svg';

const styles = (theme) => ({
	customAppBar: {
		position: 'fixed',
		left: 0,
		right: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		height: pxToRem(80),
		padding: `0 ${pxToRem(60)}`,
		backgroundColor: '#fff',
		zIndex: '1',
		boxShadow: '0px 10px 20px rgba(151, 169, 204, 0.17)',
		[theme.breakpoints.down('sm')]: {
			padding: `0 ${pxToRem(10)}`,
			minHeight: pxToRem(80),
			height: 'auto'
		}
	},
	navMain: {
		display: 'flex',
		alignItems: 'center',
		height: '100%'
	},
	drawerPaper: {
		top: pxToRem(80),
		left: 0,
		background: `#fff url(${pattern}) no-repeat top 40% left`
	},
	navAside: {
		display: 'flex',
		alignItems: 'center'
	},
	mobileNavAside: {
		height: '100%',
		justifyContent: 'center',
		alignItems: 'center'
	},
	languageIcon: {
		cursor: 'pointer',
		marginRight: pxToRem(10),
		opacity: 0.5,
		'& img': {
			padding: `${pxToRem(2.45)} ${pxToRem(2)} ${pxToRem(2.5)} ${pxToRem(3)}`,
			border: 'solid 2px transparent',
			'&:hover': {
				outline: '0px solid transparent',
				borderRadius: '50%',
				border: `solid 2px ${theme.palette.primary.main}`
			}
		}
	},
	activeLanguage: {
		opacity: 'unset',
		'& img': {
			outline: '0px solid transparent',
			borderRadius: '50%',
			border: `solid 2px ${theme.palette.primary.main}`
		}
	},
	link: {
		tapHighlightColor: 'transparent'
	},
	navLink: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		margin: `0 ${pxToRem(20)}`,
		textDecoration: 'none',
		color: '#460F76',
		fontWeight: 600,
		tapHighlightColor: 'transparent',
		borderBottom: '2px solid transparent',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			width: 'fit-content',
			fontSize: pxToRem(28),
			fontWeight: 'bold',
			justifyContent: 'center',
			margin: `${pxToRem(40)} auto`
		},
		'&:active': {
			color: theme.palette.primary.light,
			backgroundColor: 'transparent'
		},
		'&:hover': {
			backgroundColor: 'transparent',
			textDecoration: 'none',
			borderRadius: 0,
			borderBottom: `solid 2px ${theme.palette.primary.light}`
		}
	},
	activeLink: {
		backgroundColor: 'transparent',
		textDecoration: 'none',
		borderRadius: 0,
		borderBottom: `solid 2px ${theme.palette.primary.light}`
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		height: pxToRem(38),
		borderRadius: pxToRem(19),
		borderBottom: 0,
		padding: `0 ${pxToRem(20)}`,
		margin: `0 ${pxToRem(20)}`,
		tapHighlightColor: 'transparent',
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			width: 'fit-content',
			padding: 0,
			justifyContent: 'center',
			fontSize: pxToRem(28),
			fontWeight: 'bold',
			margin: `${pxToRem(40)} auto`,
			borderRadius: 0,
			backgroundColor: 'transparent',
			color: '#460F76'
		},
		'&:hover': {
			backgroundColor: '#0D89C7',
			textDecoration: 'none',
			[theme.breakpoints.down('sm')]: {
				backgroundColor: 'transparent',
				borderBottom: `solid 2px ${theme.palette.primary.light}`
			}
		}
	},
	activeButton: {
		backgroundColor: theme.palette.primary.light,
		[theme.breakpoints.down('sm')]: {
			backgroundColor: 'transparent',
			borderBottom: `solid 2px ${theme.palette.primary.light}`
		},
		'&:hover': {
			backgroundColor: '#7334AB',
			[theme.breakpoints.down('sm')]: {
				backgroundColor: 'transparent',
				borderBottom: `solid 2px ${theme.palette.primary.light}`
			}
		}
	},
	menuIcon: {
		color: theme.palette.primary.light
	},
	img: {
		paddingRight: pxToRem(62),
		[theme.breakpoints.down('sm')]: {
			paddingRight: pxToRem(10),
			paddingLeft: pxToRem(10)
		}
	}
});

class Nav extends React.Component {
	state = {
		path: window.location.pathname.slice(1),
		openMenu: false,
		languageCode: window.localStorage.lang || 'en'
	};

	componentDidUpdate() {
		const { location } = this.props;
		const { path } = this.state;

		if (location.pathname.slice(1) !== path) {
			this.setState({
				path: location.pathname.slice(1)
			})
		}
	}

	toggleDrawer = () => {
		this.setState((state) => ({
			openMenu: !state.openMenu
		}));
	};

	handleClick = (path) => {
		this.setState((state) => ({
			path: window.location.pathname.slice(1)
		}));
	};

	chooseLanguage = (code) => {
		window.localStorage.lang = code;
		window.location.reload();

		this.setState((state) => ({
			languageCode: code
		}));
	};

	render() {
		const { classes, } = this.props;
		const { path, openMenu, languageCode } = this.state;

		const languageLinks = languages.map((language) => (
			<div
				key={language.code}
				className={classNames(classes.languageIcon, languageCode === language.code ? classes.activeLanguage : null)}
				onClick={this.chooseLanguage.bind(this, language.code)}
			>
				<Image src={language.img} alt={language.name} />
			</div>
		));

		const NavLinksNode = () => {
			const { i18n } = useTranslation();

			return navLinks.map((link) => {
				if (link.href) {
					return (
						<a
							key={link.name}
							href={link.href}
							className={classes.navLink}
							target="_blank"
							rel="noopener noreferrer"
							title={i18n.t(link.name)}
						>
							{i18n.t(link.name)}
						</a>
					);
				}

				return (
					<Link
						selected={false}
						key={link.name}
						to={link.path}
						className={classNames(
							link.button ? classes.button : classes.navLink,
							path === link.path ? (link.button ? classes.activeButton : classes.activeLink) : null
						)}
						onClick={this.handleClick.bind(this, link.path)}
					>
						{i18n.t(link.name)}
					</Link>
				);
			});
		};

		return (
			<div className={classes.customAppBar}>
				<div className={classes.navMain}>
					<Link to="/" className={classes.link} onClick={this.handleClick.bind(this, '/')}>
						<img src={logo} className={classes.img} alt="CyberAplaca logo" />
					</Link>
					<Drawer
						anchor="right"
						open={openMenu}
						onClose={this.toggleDrawer}
						variant="persistent"
						classes={{
							paper: classes.drawerPaper
						}}
					>
						<div tabIndex={0} role="button" onClick={this.toggleDrawer}>
							<NavLinksNode />
						</div>
						<div className={classNames(classes.navAside, classes.mobileNavAside)}>{languageLinks}</div>
					</Drawer>
					<Hidden smDown>
						<NavLinksNode />
					</Hidden>
				</div>
				<Hidden smDown>
					<div className={classes.navAside}>{languageLinks}</div>
				</Hidden>
				<Hidden mdUp>
					<IconButton onClick={this.toggleDrawer} className={classes.menuButton} color="inherit" aria-label="Menu">
						{openMenu ? <Close className={classes.menuIcon} /> : <MenuIcon className={classes.menuIcon} />}
					</IconButton>
				</Hidden>
			</div>
		);
	}
}

Nav.propTypes = {
	classes: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(Nav)
