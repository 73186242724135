import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Container from '../components/Container';
import FluidContent from '../components/FluidContent';
import { Paper, Grid, Typography, withStyles } from '@material-ui/core';

import pxToRem from '../lib/pxToRem';
import { contact } from '../data/contact';

import logo from '../images/cyberalpaca-logo-horizontal.svg';
import contactBg from '../images/background/contact-bg.jpg';
import contactPattern from '../images/background/contact-pattern.jpg';
import iconLine from '../images/icons/short-line.svg';
import Image from '../components/Image';

const styles = (theme) => ({
	container: {
		paddingBottom: 0
	},
	h1: {
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			margin: `${pxToRem(10)} 0 ${pxToRem(50)} 0`
		}
	},
	content: {
		width: '100%',
		background: `url(${contactBg}) no-repeat center`,
		[theme.breakpoints.down('sm')]: {
			background: `url(${contactPattern}) no-repeat center`
		}
	},
	paper: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		width: pxToRem(370),
		height: pxToRem(150),
		marginBottom: pxToRem(30),
		padding: `0 ${pxToRem(45)}`,
		borderRadius: pxToRem(18),
		boxShadow: '0px 15px 40px rgba(151, 169, 204, 0.2)',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			maxHeight: pxToRem(165)
		}
	},
	paperHeader: {
		display: 'flex',
		alignItems: 'center',
		whiteSpace: 'nowrap',
		textTransform: 'uppercase',
		'& p': {
			fontWeight: 'bold',
			marginRight: pxToRem(18)
		}
	},
	paperValue: {
		marginTop: pxToRem(20),
		[theme.breakpoints.down('sm')]: {
			marginTop: pxToRem(15)
		},
		'& a': {
			fontSize: pxToRem(18),
			fontWeight: 600,
			lineHeight: pxToRem(30),
			letterSpacing: pxToRem(0.65),
			color: '#3B465C',
			[theme.breakpoints.down('sm')]: {
				fontSize: pxToRem(14)
			}
		}
	},
	fluidSection: {
		backgroundColor: theme.palette.background.paper
	},
	fluidContentlink: {
		fontSize: pxToRem(22),
		lineHeight: pxToRem(22),
		fontWeight: 'bold',
		color: theme.palette.primary.light
	}
});

function Contact(props) {
	const { classes } = props;
	const { i18n } = useTranslation();

	return (
		<main>
			<section>
				<Container className={classes.container}>
					<Grid container justify="center">
						<Typography variant="h1" className={classes.h1}>
							{i18n.t('Contact Us')}
						</Typography>
					</Grid>
				</Container>
				<div className={classes.content}>
					<Container>
						{contact.map((element) => (
							<Grid key={element.name} container>
								<Paper className={classes.paper}>
									<div className={classes.paperHeader}>
										<Typography>{i18n.t(element.name)}</Typography>
										<Image src={iconLine} alt="Icon line" />
									</div>
									<div className={classes.paperValue}>
										<a href={element.src} title={i18n.t(element.name)} target="_blank" rel="noopener noreferrer">
											{element.value}
										</a>
									</div>
								</Paper>
							</Grid>
						))}
					</Container>
				</div>
			</section>

			<section className={classes.fluidSection}>
				<Container>
					<FluidContent title={i18n.t('Join Our Team')} image={logo} alt="Cyberalpaca logo">
						<Typography variant="h3" color="textPrimary" gutterBottom>
							{i18n.t('joinOurTeamSubtitle')} <span>{i18n.t('ambitious')}</span> {i18n.t('and')}{' '}
							<span> {i18n.t('interesting')}</span> {i18n.t('projects')}.
						</Typography>

						<Typography color="textSecondary" paragraph gutterBottom>
							{i18n.t('joinOurTeamText')}
							<br />
							{i18n.t('joinOurTeamLine')}
							<br />
							{i18n.t('Please send us your CV to')}
						</Typography>

						<Typography color="secondary" paragraph>
							<a href="mailto:jobs@cyberalpaca.com" className={classes.fluidContentlink}>
								jobs@cyberalpaca.com
							</a>
						</Typography>
					</FluidContent>
				</Container>
			</section>
		</main>
	);
}

Contact.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Contact);
