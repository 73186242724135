import React from 'react';
import ReactDOM from 'react-dom';
import JssProvider from 'react-jss/lib/JssProvider';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import { MuiThemeProvider, createGenerateClassName } from '@material-ui/core/styles';

import About from './routes/About';
import Contact from './routes/Contact';
import Home from './routes/Home';
import Services from './routes/Services';
import Industries from './routes/Industries';

import Team from './routes/Team';
import NotFound from './routes/NotFound';

import Nav from './components/Nav';
import Footer from './components/Footer';

import './lib/i18n';
import './index.css';
import { theme } from './data/theme';

const generateClassName = createGenerateClassName({
	dangerouslyUseGlobalCSS: false,
	productionPrefix: 'c'
});

ReactDOM.render(
	<BrowserRouter>
			<JssProvider generateClassName={generateClassName}>
				<MuiThemeProvider theme={theme}>
					<Nav />
						<ScrollToTop>
							<Switch>
								<Route exact path="/" component={Home} />
								<Route path="/about-us" component={About} />
								<Route path="/services" component={Services} />
								<Route path="/industries" component={Industries} />
								<Route path="/team" component={Team} />
								<Route path="/contact" component={Contact} />
							<Route component={NotFound} />
							</Switch>
						</ScrollToTop>
					<Footer />
				</MuiThemeProvider>
			</JssProvider>
	</BrowserRouter>,
	document.getElementById('root')
);
