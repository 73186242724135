import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from '../i18n/en.json';
// TODO: uncomment when translation will be ready
// import translationDE from '../i18n/de.json';
// import translationFR from '../i18n/fr.json';

const resources = {
	en: {
		translation: translationEN
	},
	// de: {
	// 	translation: translationDE
	// },
	// fr: {
	// 	translation: translationFR
	// }
};

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: window.localStorage.lang || 'en',

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
