import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Container from '../components/Container';
import ServicesList from '../components/ServicesList';
import CustomButton from '../components/CustomButton';
import FluidContent from '../components/FluidContent';
import SquishExtraService from '../components/SquishExtraService';

import { Typography, withStyles } from '@material-ui/core';
import logo from '../images/icons/racket.svg';
import servicesBg from '../images/background/services-bg.svg';

const styles = (theme) => ({
	services: {
		background: `#fff url(${servicesBg}) no-repeat center/cover`
	},
	fluidSection: {
		backgroundColor: theme.palette.background.paper
	},
	linkButton: {
		'&:hover': {
			textDecoration: 'none'
		}
	},
});

function Services(props) {
	const { classes } = props;
	const { i18n } = useTranslation();

	return (
		<main>
			<section className={classes.services}>
				<ServicesList />
				<SquishExtraService />
			</section>

			<section className={classes.fluidSection}>
				<Container>
					<FluidContent title={i18n.t('joinUsTitle')} image={logo} alt="CyberAlpaca logo">
						<Typography color="textSecondary" paragraph gutterBottom className={classes.fluidContentSubtext}>
							{i18n.t('joinUsText')}
							<br />
							{i18n.t('joinUsTextLine')}
						</Typography>

						<Link to="/contact" className={classes.linkButton}>
							<CustomButton color="primary">
								{i18n.t('Contact Us')}
							</CustomButton>
						</Link>
					</FluidContent>
				</Container>
			</section>
		</main>
	);
}

Services.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Services);
