import embededTestingIcon from '../images/icons/embeded-testing.svg';
import guiTestingIcon from '../images/icons/gui-testing.svg';
import mobileTestingIcon from '../images/icons/mobile-testing.svg';
import performanceTestingIcon from '../images/icons/performance-testing.svg';
import toolDevIcon from '../images/icons/tool-dev.svg';
import webTestingIcon from '../images/icons/web-testing.svg';

export const cards = [
	{
		icon: guiTestingIcon,
		name: 'Automated GUI testing',
		path: 'services#automated-gui-testing'
	},
	{
		icon: embededTestingIcon,
		name: 'Embedded testing',
		path: 'services#embedded-testing'
	},
	{
		icon: mobileTestingIcon,
		name: 'Mobile application testing',
		path: 'services#mobile-app-testing'
	},
	{
		icon: webTestingIcon,
		name: 'Web testing',
		path: 'services#web-testing'
	},
	{
		icon: performanceTestingIcon,
		name: 'trainingConsulting',
		path: 'services#consulting-training'
	},
	{
		icon: toolDevIcon,
		name: 'Tool integration development',
		path: 'services#tool-integration-dev'
	}
];
