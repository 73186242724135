import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  withStyles
} from '@material-ui/core';

import Image from '../Image';
import pxToRem from '../../lib/pxToRem';
import { industriesRows } from '../../data/industriesRows';

const styles = theme => ({
  gridContainer: {
    marginBottom: pxToRem(90),
    [theme.breakpoints.down('sm')]: {
      marginBottom: pxToRem(30),
    },
    '&:nth-child(even)': {
      direction: 'rtl',
      textAlign: 'left',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    '&:nth-child(odd)': {
      '& h2, p': {
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
          textAlign: 'center',
        },
      },
      textAlign: 'right',
    },
  },
  gridItem: {
    [theme.breakpoints.down('xs')]: {
      order: 2,
      textAlign: 'center'
    },
  },
  gridImage: {
    [theme.breakpoints.down('xs')]: {
      order: 1,
      textAlign: 'center'
    },
  }
});

function ImageTextContent (props) {
  const { classes } = props;
  const { i18n } = useTranslation();

  return (
    <>
    { industriesRows.map(row => (
      <Grid key={row.title} container spacing={40} className={classes.gridContainer} justify="center">
        <Grid item xs={12} sm={5} className={classes.gridItem}>
          <Typography variant="h2">
            {i18n.t(row.title)}
          </Typography>
          <Typography color="textSecondary">
            {i18n.t(row.text)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridImage}>
          <Image src={row.img} alt={i18n.t(row.title)} />
        </Grid>
      </Grid>
    ))}
    </>
  );
}

ImageTextContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageTextContent);
