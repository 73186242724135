import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Container from '../components/Container';
import FluidContent from '../components/FluidContent';
import CustomButton from '../components/CustomButton';
import IndustriesImageTextContent from '../components/IndustriesImageTextContent';
import {
	Typography,
	withStyles,
	Grid
	//Hidden
} from '@material-ui/core';
import pxToRem from '../lib/pxToRem';

//import patternImg from '../images/background/pattern-button.svg';
import racketIcon from '../images/icons/racket.svg';

const styles = (theme) => ({
	fluidSection: {
		background: 'linear-gradient(0deg, rgba(251, 251, 251, 0.216401) 7.75%, rgba(240, 243, 249, 0.5) 100%)'
	},
	fluidContentSubtext: {
		width: 560,
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			width: 'auto'
		}
	},
	h1: {
		marginBottom: pxToRem(130),
		[theme.breakpoints.down('sm')]: {
			marginBottom: pxToRem(50)
		}
	},
	linkButton: {
		'&:hover': {
			textDecoration: 'none'
		}
	},
});

function Industries(props) {
	const { classes } = props;
	const { i18n } = useTranslation();

	return (
		<main>
			<section className={classes.fluidSection}>
				<Container>
					<Grid container justify="center">
						<Typography component="h1" variant="h1" className={classes.h1}>
							{i18n.t('industries')}
						</Typography>
					</Grid>

					<IndustriesImageTextContent />

					<FluidContent title={i18n.t('joinUsTitle')} image={racketIcon} alt="Racket Icon">
						<Typography color="textSecondary" paragraph gutterBottom className={classes.fluidContentSubtext}>
							{i18n.t('joinUsText')}
							<br />
							{i18n.t('joinUsTextLine')}
						</Typography>

						<Link to="/contact" className={classes.linkButton}>
							<CustomButton color="primary">
								{i18n.t('Contact Us')}
							</CustomButton>
						</Link>
					</FluidContent>
				</Container>
			</section>
		</main>
	);
}

Industries.propTypes = {
	classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Industries);
